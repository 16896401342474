<script setup lang="ts">
import { defineProps } from "vue";
import type { Group } from "~/client/models";

const { status, data } = useAuth();
const user = computed(() => (status.value === "authenticated" ? data.value?.user : null));

const props = defineProps<{
  group: Group;
}>();

const foundedString = computed(() => {
  if (!props.group?.created_at) return "";
  return new Date(props.group.created_at)?.toLocaleDateString();
});

const isOwner = computed(() => props.group.gamemaster.email === user.value?.email);
const isPlayer = computed(() => props.group.players?.map((p) => p.email).includes(user.value?.email || ""));
</script>

<template v-if="group">
  <div>
    <Card class="min-w-64 pt-6">
      <template v-slot:buttons>
        <nuxt-link v-if="isPlayer || isOwner" :to="`/group/${props.group?.id}`">
          <button-small class="h-12 w-12 bg-cyan-600 hover:bg-cyan-400">
            <img class="w-8" src="@icons/document-details.svg" role="link" />
          </button-small>
        </nuxt-link>
        <nuxt-link v-if="isOwner" :to="`/group/edit-${props.group?.id}`">
          <button-small class="h-12 w-12 bg-amber-600 hover:bg-amber-400">
            <img class="w-8" src="@icons/pencil-square.svg" role="link" />
          </button-small>
        </nuxt-link>
      </template>
      <template v-slot:content>
        <div class="flex">
          <h3 class="text-2xl font-semibold">{{ group.name }}</h3>
          <div class="grow" />
          <h3 class="font-semibold">{{ foundedString }}</h3>
        </div>
        <div class="mt-3 flex items-center justify-items-start gap-9">
          <div>
            <p class="font-semibold">Location</p>
            <p>{{ group.location?.name ?? group.location }}</p>
          </div>
          <div>
            <p class="font-semibold">Lore</p>
            <p>{{ group.system?.name ?? group.system }}</p>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
